import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import ImageModal from '../components/ImageModal';
import '../components/DesignPage.css';

const InteriorDesignPage = () => {
    const [projects, setProjects] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await axios.get('https://amywagnerdesigns.com/api/projects/InteriorDesign');
            console.log("Received projects data:", response.data);
            setProjects(response.data.projects);
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    const deleteProject = async (projectId) => {
        try {
            await axios.delete(`https://amywagnerdesigns.com/api/projects/${projectId}`);
            fetchProjects();
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    // Function to handle image click and set the selected image
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <div className="design-page">
            <div className="design-banner">Interior Design</div>
            {isLoggedIn && (
                <Link to="/create-project" className="design-button">Create New Project</Link>
            )}
            <div className="projects-container">
                {projects.map(project => (
                    <div key={project.project_id} className="design-project">
                        <h2>{project.project_name}</h2>
                        <p>{project.project_description}</p>
                        <div className="images-container">
                            {project.images && project.images.map((image, index) => (
                                <div className="image-wrapper" key={index} onClick={() => handleImageClick(`https://amywagnerdesigns.com${image}`)}>
                                    <img src={`${image}`} alt={`${project.project_name} ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                        {isLoggedIn && (
                            <>
                                <button onClick={() => deleteProject(project.project_id)} className="design-button">Delete</button>
                                {/* The edit button/link will go here */}
                            </>
                        )}
                    </div>
                ))}
            </div>
            {selectedImage && <ImageModal imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />}
            <div className="schedule-section">
                <p>Schedule Your Initial Consult Today!</p>
                <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
                    <button className="schedule-button">
                        Schedule Today!
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default InteriorDesignPage;

