import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProjectForm = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [page, setPage] = useState('');
    const [images, setImages] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('project_name', projectName);
        formData.append('project_description', projectDescription);
        formData.append('page', page);
        for (let i = 0; i < images.length; i++) {
            formData.append('images', images[i]);
        }

        try {
            await axios.post('https://amywagnerdesigns.com/api/projects', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate(`/${page}`);
        } catch (error) {
            console.error("Error creating project:", error.response.data);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>
                    Project Name:
                    <input type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} required />
                </label>
            </div>
            <div>
                <label>
                    Project Description:
                    <textarea value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} required />
                </label>
            </div>
            <div>
                <label>
                    Page:
                    <select value={page} onChange={(e) => setPage(e.target.value)} required>
                        <option value="">Select Page</option>
                        <option value="HomeRemodeling">Home Remodeling</option>
                        <option value="Gallery">Gallery</option>
                        <option value="InteriorDesign">Interior Design</option>
                    </select>
                </label>
            </div>
            <div>
                <label>
                    Upload Images:
                    <input type="file" multiple onChange={(e) => setImages([...e.target.files])} />
                </label>
            </div>
            <button type="submit">Create Project</button>
        </form>
    );
};

export default ProjectForm;




