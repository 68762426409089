import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const response = await axios.get('https://amywagnerdesigns.com/api/admin/dashboard', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Token from local storage
                },
            });
            setDashboardData(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            setLoading(false);
        }
    };

    const approveTestimonial = async (id) => {
        try {
            await axios.put(
                `https://amywagnerdesigns.com/api/testimonials/${id}/approve`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            alert('Testimonial approved successfully!');
            fetchDashboardData(); // Refresh dashboard data
        } catch (error) {
            console.error('Error approving testimonial:', error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="admin-dashboard">
            <h1>Dashboard</h1>

            <section>
                <h2>Pending Testimonials</h2>
                {dashboardData.unapprovedTestimonials.length > 0 ? (
                    <ul>
                        {dashboardData.unapprovedTestimonials.map((testimonial) => (
                            <li key={testimonial.id}>
                                <p>"{testimonial.content}"</p>
                                <p><strong>- {testimonial.name}</strong></p>
                                <button onClick={() => approveTestimonial(testimonial.id)}>Approve</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No testimonials to review.</p>
                )}
            </section>

            {/* Add other sections for user-relevant data */}
        </div>
    );
};

export default AdminDashboard;
